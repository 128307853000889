<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-25 pb-10">
         <div class="container">
            <div class="breadcrumb__wrapper d-flex align-items-center">
               <nav class="col" aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item"><router-link to="/produtos">Produtos</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">ID {{ produto.id }}</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

      <!-- product area start -->
      <section class="product__area pt-10 pb-30">
         <div class="container">
            <div class="row">
               <div class="col-xxl-5 col-xl-5 col-lg-6">
                  <div class="product__details-nav d-sm-flex">
                     <foto :index="0" :foto="{'url': produto.nomeArquivo, 'codigo': produto.id, 'tipo': 'Imagem'}" :location="'UNICO'" :container="'PRODUTO'" 
                        @visualizar="verMidia($event)" /> 

                     <div v-if="produto.nomeArquivo == null" class="tab-pane fade show active" role="tabpanel">
                        <div class="product__details-nav-thumb ratio ratio-1x1"><img src="#" @error="imageError"></div>
                     </div>
                  </div>
               </div>

               <div class="col-xxl-7 col-xl-7 col-lg-6">
                  <div class="product__details-wrapper">
                     <div class="product__details">
                        <h3 class="product__details-title mb-2"><a href="javascript:;">{{ produto.nome }}</a></h3>
                        <div class="product__review d-sm-flex mb-15">
                           <div class="rating rating__shop">
                              <ul>
                                 <li class="me-3"><a :href="'https://www.facebook.com/sharer/sharer.php?u='+ url" target="_blank"><i class="fab fa-facebook-square text-facebook font-20"></i></a></li>
                                 <li class="me-3"><a :href="'https://wa.me/?text='+ url" target="_blank"><i class="fab fa-whatsapp-square text-success font-20"></i></a></li>
                                 <li><a :href="'mailto:?subject='+ produto.nome +' em CCM do Brasil &amp;body=Saiba mais em nosso site '+ url" target="_blank"><i class="fas fa-envelope font-20"></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div class="product__price mb-20">
                           <span class="new text-danger mb-0" v-if="parseFloat(produto.valor) > 0"><small>R$ </small>{{ produto.valor == null ? '-' : parseFloat(produto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                           <span class="new text-danger mb-0" v-else>Gratuito</span>
                        </div>
                        <div class="product__details-des mb-25" v-if="produto.descricao != null">
                           <p>{{ produto.descricao }}</p>
                        </div>
                        <div class="product__details-quantity d-flex align-items-center" v-if="isLogado">
                           <button class="btn bg-theme text-white font-13 me-3" @click="addCarrinho(produto)">
                              <i class="far fa-shopping-cart me-1"></i><span class="d-none d-sm-inline"> Adicionar ao carrinho</span>
                           </button>
                           <button class="btn bg-secondary text-dark font-13" @click="abrirDocumentacao(produto)" v-show="String(produto.linkDocumentacao).startsWith('http')">
                              <i class="far fa-books me-1"></i><span class="d-none d-sm-inline"> Documentação</span>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- detalhes area start -->
      <section class="detalhes__area pt-40 pb-200 grey-bg">
         <div class="container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Descrição</h3>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item mb-1" v-for="(modulo, index) in produto.modulos" :key="index">
                        <h2 class="accordion-header" :id="'heading' + index">
                           <button class="accordion-button collapsed py-12" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                              <span class="accordion-icon"><img :src="modulo.nomeArquivo == null ? '' : modulo.nomeArquivo" @error="imageError" /></span>
                              <span class="weight-500 font-15">{{ modulo.nome }}</span>
                           </button>
                        </h2>
                        <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <div class="row">
                                 <div class="col-12 mt-1 mb-3">{{ modulo.descricao }}</div>
                                 <div class="col-12" v-for="(bloco, index) in modulo.blocos" :key="index">
                                    <div class="card mb-1">
                                       <div class="card-body">
                                          <h4 class="limitador font-18 mb-2">{{bloco.titulo}}</h4>
                                          <p class="text-secondary font-15 mb-2"><i class="far fa-books font-12 me-1"></i>Aulas</p>

                                          <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''" v-for="(aula, index) in bloco.aulas" :key="index">
                                             <div class="card-body p-12">
                                                <div class="row align-items-center">
                                                   <div class="w-max-content">
                                                      <i class="far fa-play color-theme font-15"></i>
                                                   </div>
                                                   <div class="col-md">
                                                      <h6 class="font-15 mb-0">{{ aula.titulo }}</h6>
                                                   </div>
                                                </div>
                                             </div>                           
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="accordion" id="accordionExample2">
                     <div class="accordion-item mb-1" v-for="(produto, index) in produto.produtos" :key="index">
                        <h2 class="accordion-header" :id="'headingB' + index">
                           <button class="accordion-button collapsed py-12" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseB' + index" aria-expanded="false" :aria-controls="'collapseB' + index">
                              <span class="accordion-icon"><img :src="produto.nomeArquivo == null ? '' : produto.nomeArquivo" @error="imageError" /></span>
                              <span class="weight-500 font-15">{{ produto.nome }}</span>
                           </button>
                        </h2>
                        <div :id="'collapseB' + index" class="accordion-collapse collapse" :aria-labelledby="'headingB' + index" data-bs-parent="#accordionExample2">
                           <div class="accordion-body">
                              <div class="col-12 mt-1 mb-3">{{ produto.descricao }}</div>
                              <div class="col-12">
                                 <div class="ratio ratio-16x9" v-if="String(produto.link).startsWith('https://www.youtube.com/embed/') || String(produto.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                                    <iframe class="rounded" :src="produto.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" 
                                       allowfullscreen>
                                    </iframe>
                                 </div>
                              </div>
                              <div class="col-12">
                                 <div class="cards-5">
                                    <div class="imageContainer cursor-pointer" v-for="(foto, index) in produto.listFotos" :key="index" @click="verMidia(foto)">
                                       <img class="rounded cursoVinc" :src="foto.urlImage == null ? '' : foto.urlImage" @error="imageError">
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- modalVisualizar -->
      <div class="modal fade" id="modalVisualizar" tabindex="-1" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body p-0" v-if="midia.tipo == 'Vídeo'">
                  <div class="ratio ratio-16x9" v-if="String(midia.url).startsWith('https://www.youtube.com/embed/')">
                     <iframe class="embed-responsive-item" title="video" :src="midia.url" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="ratio ratio-16x9" v-else>
                     <video class="embed-responsive-item" width="320" height="240" title="video" :src="midia.url +'#t=0.5'" controls>Your browser does not support the video.</video>
                  </div>
               </div>
               <div class="modal-body p-0" v-else>
                  <div class="ratio ratio-16x9">
                     <img class="embed-responsive-item" width="320" height="240" title="Foto" :src="midia.url" />
                  </div>
               </div>
            </div>
         </div> 
      </div>
   </main>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import foto from '@/components/produtos/Foto.vue'

export default {
   name: 'Produto',
   data : function () {
      return {
         url: window.location.href,
         midia: {'tipo': 'Vídeo', 'url': 'https://www.youtube.com/embed/89CyW6RViuk?si=UX7CDK9zUq2H6Cya'},
         produto: {"id": null,"nome":"","descricao":"","valor":0,"nomeArquivo":"","tipo":"","isPromocao":false,"isDesabilitado":false,"isDestaque":false,"tags":null,"qtdVendidos":0,"data":null,"linkDocumentacao":null,"urlImage":null,"jaPossuiPacote":false,"qtdModulos":null,"qtdProdutos":null,"totalViews":null,"modulos":[],"produtos":[]}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest,
         isLogado: state => state.isLogado
		})
	},
   watch: {
      '$route.params.id': {
         handler: function () {
            this.getProduto()
         },
         deep: true,
         immediate: true
      }
   },
   components: {
      foto
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      verMidia : function (arquivo) {
         let ref = this;
         this.midia = {'tipo': 'Imagem', 'url': arquivo.nomeArquivo}

         $("#modalVisualizar").modal("show")
         $("#modalVisualizar").on('hidden.bs.modal', function () {
            ref.midia = {'tipo': 'Vídeo', 'url': 'https://www.youtube.com/embed/89CyW6RViuk?si=UX7CDK9zUq2H6Cya'}
         })
      },
      addCarrinho : function (produto) {
         this.$store.dispatch('addCarrinho', JSON.parse(JSON.stringify(produto)))
         this.produto.quantidade = produto.qtdMult;
      },
      getProduto : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'servicos/searchPacoteById',
            params: {
               id: this.$route.params.id
            }
			}).then(response => {
            this.produto = response.data

			}).catch(function (error) {
				if (error.response != undefined) {
               if (error.response.status == 400) {
                  router.push('/').catch(function(){})
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            window.scrollTo(0, 0)
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      abrirDocumentacao : function (produto) {
         window.open(produto.linkDocumentacao)
      }
   }
}

</script>

<style scoped>

#modalVisualizar .modal-dialog {
   max-width: 1000px;
   margin: 30px auto;
}

#modalVisualizar .modal-content {
   background-color: #0000 !important;
   border-color: #0000 !important;
}

#modalVisualizar .modal-content img {
   object-fit: contain;
}

.product__details-nav-thumb {
   border: 1px solid #d6dce1;
   background-color: #fff;
   border-radius: 4px;
}

.cards-5 {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

.imageContainer {
   position: relative;
   width: 100%;
   height: 100%;
}

.imageContainer i {
   position: absolute;
   top: 0;
   left: 0;
   margin: 10px; /* Ajuste conforme necessário para espaçamento do ícone em relação às bordas */
}

.cursoVinc {
   width: 100%;
   object-fit: cover;
   aspect-ratio: 3/4;
}

</style>